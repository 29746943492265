import axios from "axios";

const BASE_URL = 'https://onlineteercounter.xyz/'

export const loginAPI = (body) =>
    axios.post(`${BASE_URL}login_user.php`,body);

export const signUpAPI = (body) =>
    axios.post(`${BASE_URL}register_user.php`,body);

export const createOTPAPI = (body) =>
    axios.post(`${BASE_URL}create_otp.php`,body);

export const resetPasswordAPI = (body) =>
    axios.post(`${BASE_URL}reset_user_password.php`,body);

export const gameListAPI = (body) =>
    axios.post(`${BASE_URL}get_all_games_meta.php`,body);

export const sliderImageAPI = (body) =>
    axios.post(`${BASE_URL}fetch_slider_images.php`,body);

export const userDataAPI = (body) =>
    axios.post(`${BASE_URL}fetch_user_meta_data.php`,body);

export const playGameAPI = (body) =>
    axios.post(`${BASE_URL}play_teer_game.php`,body);

export const getFundAPI = (body) =>
    axios.post(`${BASE_URL}get_fund_meta.php`,body);

export const withdrawAPI = (body) =>
    axios.post(`${BASE_URL}user_request_withdraw.php`,body);

export const getWithdrawHistoryAPI = (body) =>
    axios.post(`${BASE_URL}fetch_user_wallet_history.php`,body);

export const getBankDetailsAPI = (body) =>
    axios.post(`${BASE_URL}fetch_user_bank_detail.php`,body);

export const updateBankDetailsAPI = (body) =>
    axios.post(`${BASE_URL}set_user_bank_detail.php`,body);

export const directFCGameAPI = (body) =>
    axios.post(`${BASE_URL}play_f_c_game.php`,body);

export const bothSideFCGameAPI = (body) =>
    axios.post(`${BASE_URL}play_both_side_fc_game.php`,body);

export const sameFCGameAPI = (body) =>
    axios.post(`${BASE_URL}play_same_side_fc_game.php`,body);

export const teerGameHistoryAPI = (body) =>
    axios.post(`${BASE_URL}fetch_user_teer_bid_history.php`,body);

export const FCGameHistoryAPI = (body) =>
    axios.post(`${BASE_URL}fetch_user_bid_history.php`,body);

export const teerGameResultAPI = (body) =>
    axios.post(`${BASE_URL}fetch_all_teer_results.php`,body);

export const contactUsAPI = (body) =>
    axios.post(`${BASE_URL}fetch_contact_us_meta.php`,body);

export const gameRateAPI = (body) =>
    axios.post(`${BASE_URL}fetch_game_rates.php`,body);