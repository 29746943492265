import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import { API_KEY } from '../utils/constants';
import { getFundAPI } from '../api/api';
import Loader from '../components/loader';
import { toast } from 'react-toastify';

function Add() {
  const [fund, setFund] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const getFundData = async () => {
    const formData = new FormData();
    formData.append('__api_key__', API_KEY);
    await getFundAPI(formData)
      .then((res) => {
        if (res.data.state === 'OK') {
          setFund(res.data.data.fund);
          setIsLoading(false)
        }
      })
      .catch((error) => console.log(error));
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(
      text
    );

    toast.success('Copied To clipboard');
  };
  
  const downloadImage = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'image/jpeg',
          'Access-Control-Allow-Origin': '*',
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'downloaded_image.jpg';
      document.body.appendChild(link); // For Firefox
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading the image:', error);
      toast.error('Failed to download image');
    }
  };
  // const downloadImage = async (imageUrl) => {
  //   const link = document.createElement('a');
  //   link.href = imageUrl;
  //   link.download = 'downloaded_image.jpg';
  //   link.click();
  // };

  useEffect(() => {
    getFundData();
  }, []);

  return (
    <div className='main-2 bg-3'>
      <Header title='Add' isBack />
      <div className='container mt-3'>
        <h6 className='text-white fw-bold'>UPI ID</h6>
        <div className='w-100 d-flex justify-content-between align-items-center'>
          <text className='text-white'>{fund.upi_id}</text>
          {fund.upi_id != '' && <button className='btn rounded-5 border-1 border bg-white' style={{color: '#6c62ff'}} onClick={()=>handleCopy(fund.upi_id)} >Copy</button>}
        </div>
        <div className='w-100 d-flex justify-content-between mt-3 align-items-center'>
          <text className='text-white'>{fund.upi_id2}</text>
          {fund.upi_id2 != '' && <button className='btn rounded-5 border-1 border bg-white' style={{color: '#6c62ff'}} onClick={()=>handleCopy(fund.upi_id2)}>Copy</button>}
        </div>
        <div className='d-flex align-items-center flex-column text-center mt-5 pt-5'>
          <img
            src={fund.image}
            style={{ width: 'auto', height: 350 }}
            alt='profile'
          />
          <button className='btn rounded-5 my-3 px-3 text-white' style={{backgroundColor: '#6c62ff'}} onClick={()=> downloadImage(fund.image)}>
            Download QR
          </button>
          <h5>
            {fund.text}
          </h5>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default Add;
