import React, { useState } from 'react';
import Header from '../components/header';
import { useLocation, useNavigate } from 'react-router-dom';
import TimeCountDown from '../components/timeCountDown';
import { toast } from 'react-toastify';
import Loader from '../components/loader';

function FCGame() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isGameClose, setIsGameClose] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const { name, uid, open_time, close_time } = location.state || {};
  return (
    <div className='main-2'>
      <Header title={name} isBack bgColor />
      <div className='container pt-3'>
        <div className=' d-flex justify-content-between p-4 shadow rounded-2 text-center'>
          <div>
            <p>Open</p>
            <h6 className='fw-bold'>{open_time}</h6>
          </div>
          <div>
            <p>Time remaining</p>
            <h6 className='fw-bold' style={{color: '#6c62ff'}}>
            <TimeCountDown setIsGameClose={setIsGameClose} targetTime={close_time} setIsLoading={setIsLoading} />
            </h6>
          </div>
          <div>
            <p>Close</p>
            <h6 className='fw-bold'>{close_time}</h6>
          </div>
        </div>
      </div>
      {(name == 'KHANAPARA BS' || name == 'SHILLONG BS') ?
      (<div className='container my-auto'>
        <button
          className='play-button mt-3 btn'
          onClick={() =>
            {!isGameClose ? navigate('/bs-singlenumberplay', {
              state: { name: name, type: 'Single Number Play', game_uid: uid },
            }) : toast.error('Game not started yet')}
          }
        >
          Single Number Play
        </button>
        <button
          className='play-button mt-3 btn'
          onClick={() =>
            {!isGameClose ? navigate('/bs-houseplay', {
              state: { name: name, type: 'House Play', game_uid: uid },
            }): toast.error('Game not started yet')}
          }
        >
          House Play
        </button>
        <button
          className='play-button mt-3 btn'
          onClick={() =>
            {!isGameClose ? navigate('/bs-endingplay', {
              state: { name: name, type: 'Ending Play', game_uid: uid },
            }) :toast.error('Game not started yet')}
          }
        >
          Ending Play
        </button>
      </div>)
:
      (<div className='container my-auto'>
        <button
          className='play-button mt-3 btn'
          onClick={() =>
            {!isGameClose ? navigate('/direct-fc', {
              state: { name: name, type: 'Direct FC', game_uid: uid },
            }) : toast.error('Game not started yet')}
          }
        >
          Direct FC
        </button>
        <button
          className='play-button mt-3 btn'
          onClick={() =>
            {isGameClose ? navigate('/bothside-fc', {
              state: { name: name, type: 'BothSide FC', game_uid: uid },
            }): toast.error('Game not started yet')}
          }
        >
          Both Side FC
        </button>
        <button
          className='play-button mt-3 btn'
          onClick={() =>
            {isGameClose ? navigate('/same-fc', {
              state: { name: name, type: 'Same FC', game_uid: uid },
            }) :toast.error('Game not started yet')}
          }
        >
          Same FC
        </button>
      </div>)}
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default FCGame;
